@import-normalize;

html, body {
  background-color: RGB(33,37,47);
  font-family: 'Satoshi', sans-serif;
  height: 100%;
  margin: 0px;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}

a, a:visited, a:hover, a:active, a:focus, a:link, a:-webkit-any-link  {
    text-decoration: none;
    color: inherit;
}

ul, ol {
  word-break: break-all;
}

li {
  display: inline;
}
